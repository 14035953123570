<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">环境影响评估</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="ZRHJJCXMBH"
                    label="自然环境监测项目编号"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.ZRHJJCXMBH"
                        :disabled="!isEdit || $route.query.JCDXID"
                    >
                        <el-option
                            v-for="(item, index) in ZRHJJCXMNRList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.ZRHJJCXMBH"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="CJSJ" label="月份" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.CJSJ"
                        type="month"
                        placeholder="选择监测时间"
                        value-format="yyyy-MM"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="PG" label="评估" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.PG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in PgList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="PGSM"
                    label="评估说明"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.PGSM"
                        type="textarea"
                        resize="none"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "hjyxpg",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "803",
                dataDic: {
                    glycbtid: this.heritageId,
                    ZRHJJCXMBH: "",
                    CJSJ: "",
                    PG: "",
                    PGSM: "",
                    JCDXID: null,
                    SHZT: "",
                },
            },
            rules: {
                ZRHJJCXMBH: [
                    {
                        required: true,
                        message: "请选择自然环境监测项目",
                        trigger: "change",
                    },
                ],
            },
            PgList: [],
            ZRHJJCXMNRList: [],
        };
    },
    mounted() {
        this.GetEnum();
        this.getZRHJJCXMNRList();
        this.formdata.dataDic.ZRHJJCXMBH = this.$route.query.JCDXID;
        console.log(
            "%c 🌮 this.isEdit: ",
            "font-size:20px;background-color: #4b4b4b;color:#fff;",
            this.isEdit
        );
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID", "GetGLXList"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "803",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "PG") {
                    this.PgList = v.dataItems;
                }
            });
        },
        // 获得自然环境项目列表
        async getZRHJJCXMNRList() {
            let res = await this.GetGLXList({
                itemcode: "801",
                lm: "jcsj",
            });
            this.ZRHJJCXMNRList = res.ResultValue || [];
        },
    },
};
</script>

<style></style>
